import React, {useContext} from 'react';
import {UserContext} from "../context/User";
import {formatCurrency} from "../util/util";

let WarnTopPanel = () => {
    const user = useContext(UserContext);

    const warning = user.credit_limit>0 && user.balance <= -(0.85 * user.credit_limit);
    if (!warning)
        return null;

    const strongWarn = user.credit_limit>0 && user.balance <= -user.credit_limit;

    return (
        <div className="warnTopPanelHolder">
            <div className={ 'warnTopPanel' + (strongWarn ? ' strong' : '')}>
                {strongWarn ?
                    <div>Вы превысили кредитный лимит. Пожалуйста пополните счет чтобы продолжить работу.</div>
                    :
                    <div>Ваш баланс приближаеться к кредитному лимиту. Рекомендуем пополнить счет. Обратите внимание, что при положительном балансе услуги дешевле.</div>
                }
            </div>
        </div>
    );
};

export default WarnTopPanel;