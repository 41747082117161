import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import RestrictedArea from "../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../context/User";
import LogoutButton from "./LogoutButton";
import TopStats from "./TopStats";
import WarnTopPanel from "./WarnTopPanel";

let MainMenu = () => {
    const user = useContext(UserContext);
    const cabinetStartPath = "/cabinet/" + ((user.group >= UserGroup.SUPERUSER) ? "companystats" : "fbsettings");
    return <div className="mainmenu">
        <NavLink to="/" className="mainmenu-logo">
            <div/>
        </NavLink>
        <div className="mainmenu-list">
            <NavLink exact to="/" activeClassName="active">Диплинки</NavLink>
            <RestrictedArea allowedIfOrganicsAvailable={true}>
                <NavLink to="/organics" activeClassName="active">Органика</NavLink>
            </RestrictedArea>
            {/*[PUSH]*/<NavLink exact to="/push" activeClassName="active">Пуши</NavLink>}
            <RestrictedArea allowedTo={UserGroup.USER}>
                <NavLink to={cabinetStartPath} activeClassName="active">Кабинет</NavLink>
            </RestrictedArea>
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
                <NavLink to="/admin" activeClassName="active" className="admin">Админ панель</NavLink>
            </RestrictedArea>
            <LogoutButton/>
            <TopStats/>
        </div>
    </div>
};

export default MainMenu;
