import React from 'react';
import {NavLink, Route} from "react-router-dom";
import {UserGroup} from "../../context/User";
import RestrictedArea from "../../wrappers/RestrictedArea";
import Finances from "./Finances";
import Referrals from "./Referrals";
import GameStats from "./GameStats";
import CompanyStats from "./CompanyStats";
import FBSettings from "./FBSettings";
import TelegramSettings from "./TelegramSettings";
import Deposit from "./Deposit";

const cabinetNavMenu = [
    { accessLevel: UserGroup.SUPERUSER, path: '/cabinet/companystats', icon: 'bar-chart', title: 'Статистика компании', component: CompanyStats },
    { accessLevel: UserGroup.SUPERUSER, path: '/cabinet/financelog', icon: 'coin', title: 'Финансовая история', component: Finances },
    { accessLevel: UserGroup.SUPERUSER, path: '/cabinet/gamestats', icon: 'bar-chart', title: 'Статистика по прилкам', component: GameStats },
    { accessLevel: UserGroup.USER, path: '/cabinet/fbsettings', icon: 'fb', title: 'FB кабинеты', component: FBSettings },
    { accessLevel: UserGroup.USER, path: '/cabinet/telegram', icon: 'tg', title: 'Телеграм бот', component: TelegramSettings },
    /*{ accessLevel: UserGroup.SUPERUSER, path: '/cabinet/referrals', icon: 'user', title: 'Реффералы', component: Referrals },*/
    { accessLevel: UserGroup.SUPERUSER, path: '/cabinet/deposit', icon: 'money-with-wings', title: 'Пополнить счет', component: Deposit },
];

let CabinetPage = () => (
    <div className="right-menu-page">
        <div className="menu">
            <div className="menu-list">
                {cabinetNavMenu.map(menuItem =>
                    <RestrictedArea allowedTo={menuItem.accessLevel}>
                        <NavLink to={menuItem.path} activeClassName="active" key={menuItem.path}>{menuItem.icon &&
                        <div className={'icon'} style={{backgroundImage: `url(/icons/${menuItem.icon}.png`}}/>
                        }
                            <div>{menuItem.title}</div>
                        </NavLink>
                    </RestrictedArea>
                )}
            </div>
        </div>
        <div>
            {cabinetNavMenu.map(menuItem =>
                <Route key={menuItem.path} path={menuItem.path} component={menuItem.component}/>
            )}
        </div>
    </div>
);

export default CabinetPage;