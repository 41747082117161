import React, {Component} from 'react';
//import {Link} from "react-router-dom";
import Link from '../../widgets/Link';
import Pages from '../../widgets/Pages';
import axios from "axios";
import './deeplink.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip'
import queryString from 'query-string'
import {formatCurrency, formatDateTime, isEmpty} from "../../util/util";
import {UserContext} from "../../context/User";
import CreateNewDeeplink from "./CreateNewDeeplink";
import Switch from "react-switch";

const GPLINK_TEMPLATE = 'https://play.google.com/store/apps/details?id={BUNDLE_ID}&url={URL}';

class DeeplinkList extends Component {
    filterTimer = null;
    state = {
        page: 1,
        isLast: false,
        loading: true,
        deeplinks: [],
        deeplinksToPush: [],
        isNamingOn: false,
        filter: '',
        gpLinksMode: false
    };

    componentDidMount() {
        this.fetchData(this.state.page);
        const params = queryString.parse(this.props.location.search);
        this.setState({ deeplinksToPush: [], isNamingOn: this.getUser().getFavData('is_naming_on',false), gpLinksMode: !!params.gplinks });
    }

    componentWillUnmount() {
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        let page = parseInt(params.page ? params.page : 1);
        if (page < 1) page = 1;
        if (page !== prevState.page && !this.state.loading) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(page,this.state.filter);
        }
    }

    getUser() {
        return this.context;
    }

    async fetchData(page,filter='') {
        this.setState({loading: true/*, deeplinks: []*/});

        let res = await axios.post('/deeplinklist', {page, filter});

        if (res.data)
            this.setState({...{loading: false, page: page}, ...res.data});
    }

    isDeeplinkSelectedForPush(deeplinkID) {
        return this.state.deeplinksToPush.find(dID => dID === deeplinkID);
    }

    onSelectDeeplinkForPush(deeplinkID) {
        this.setState({deeplinksToPush:
                this.isDeeplinkSelectedForPush(deeplinkID)
                ? this.state.deeplinksToPush.filter(dID => dID !== deeplinkID)
                : [...this.state.deeplinksToPush, deeplinkID]
        });
    }

    onNamingSwitch(value,e) {
        this.setState({isNamingOn: value});
        this.getUser().setFavData('is_naming_on',value,true);
    }

    onFilterChanged(e) {
        this.setState({filter: typeof(e) === 'object' ? e.target.value : e});
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
        this.filterTimer = setTimeout(() => {
            this.fetchData(1,this.state.filter);
        }, 500);
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {deeplinks,deeplinksToPush,page,isLast,loading,total_installs,isNamingOn,filter,gpLinksMode} = this.state;

        const getURI = (deeplink) => isNamingOn ? deeplink.naming : (!gpLinksMode ? deeplink.deeplink
                : GPLINK_TEMPLATE.replace('{BUNDLE_ID}', deeplink.bundle_id || '')
                                 .replace('{URL}', encodeURIComponent(deeplink.deeplink)) );

        //console.log('[QQQ]',deeplinks[0]);
        return (
            <div className="page deeplinks">
                <h1>Диплинки</h1>

                <div className="section">
                    <div className="inRow" style={{justifyContent: 'flex-start'}}>
                        <div className='headerContainer'><Link to="/newdeeplink"><button className="common">Создать</button></Link></div>
                        {/*[NAMING]*/ this.getUser().isNamingAvailable() &&
                            <div className='headerContainer inCol'>
                                <label className='clickable smallerText text-blue' htmlFor="namingSwitch">Нейминг</label>
                                <Switch
                                    checked={isNamingOn}
                                    onChange={this.onNamingSwitch.bind(this)}
                                    onColor="#c4b6a0"
                                    onHandleColor="#cb9e63"
                                    handleDiameter={24}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={18}
                                    width={40}
                                    className="react-switch"
                                    id="namingSwitch"
                                />
                            </div>
                        }
                        {/*[PUSH]*/ !isEmpty(deeplinksToPush) > 0 &&
                        <div className='headerContainer'><Link to={{pathname: '/newpush', deeplinks: deeplinksToPush}}><button className="green inRow">
                            <div className="icon pushPressedIcon"/>
                            <div className="inCol"><div>Пушнуть</div><div className="smallText">выбрано: {deeplinksToPush.length}</div></div>
                        </button></Link></div>
                        }
                    </div>
                    <div className='inRow rightAlign' style={{width: '320px'}}>
                        <div className='headerContainer inRow'>
                            <input id="filter" name="filter" type="text" value={filter} placeholder='Фильтр...' onChange={this.onFilterChanged.bind(this)} />
                            {filter && <div className='holder'>
                                <button onClick={this.onFilterChanged.bind(this,'')} className={'listSmallButton'} style={{left: '-28px', top: '-10px'}}>
                                    <div className="small2Icon crossSmall2Icon"/>
                                </button>
                            </div>}
                        </div>
                        <div className='headerContainer inRow'>
                            <div className='smallerText' style={{whiteSpace: 'nowrap'}}>Всего инсталлов:</div>
                            <div><b>{total_installs}</b></div>
                        </div>
                    </div>
                </div>

                <ul>
                {deeplinks.map(deeplink => (
                    <li key={deeplink.id}>
                        <div className="info">
                            {deeplink.name === '' ? <div className="id" /> : <div className="id">{`#${deeplink.id}`}</div>}
                            <div>
                                <div className="date">{formatDateTime(deeplink.createDate)}</div>
                                {deeplink.name === '' ? <div className="id-only">{`#${deeplink.id}`}</div> : <div className="name">{deeplink.name}</div>}
                            </div>
                            <div className='rightIcons'>{/* [PUSH] */ deeplink.pushSupport &&
                                <button className={'icon ' + (this.isDeeplinkSelectedForPush(deeplink.id) ? 'pushPressedIcon' : 'pushIcon')} data-tip="Выбрать для пуша" data-for={'tooltipPush'+deeplink.id}
                                        onClick={this.onSelectDeeplinkForPush.bind(this,deeplink.id)}/>
                            }</div>
                        </div>
                        <div className="deeplink">
                            {(isNamingOn && !deeplink.naming) ?
                                <div className='tip text-gray'>Нейминг не поддерживается</div>
                            :
                            <div className={gpLinksMode && !isNamingOn ? 'smallText' : ''}>
                                <CopyToClipboard text={getURI(deeplink)}
                                                 onCopy={() => this.setState({copied: true})}>
                                    <button type="button" className="copy-to-clipboard" data-tip="Скопировать"
                                            data-for={'tooltip' + deeplink.id}/>
                                </CopyToClipboard>
                                {getURI(deeplink)}
                            </div>
                            }
                        </div>
                        <div className="targetLink">
                            {deeplink.targetLink}
                        </div>
                        <div className="edit">
                            <Link to={`/editdeeplink/?id=${deeplink.id}`} data-tip="Редактировать" data-for={'tooltipEdit'+deeplink.id}>
                                <button type="button" className="edit"/>
                            </Link>
                        </div>
                        <div className="stats">
                            <Link to={`/stats/?id=${deeplink.id}`} data-tip="Статистика" data-for={'tooltip'+deeplink.id}>
                                <div className={deeplink.installCount === 0 ? 'zero' : ''}>{deeplink.installCount}</div>
                                <button type="button" className="button-stats"/>
                            </Link>
                        </div>
                        <ReactTooltip id={'tooltipPush'+deeplink.id} delayShow={1000} type="light" effect="solid" />
                        <ReactTooltip id={'tooltip'+deeplink.id} delayShow={100} type="light" effect="solid" />
                        <ReactTooltip id={'tooltipEdit'+deeplink.id} delayShow={100} type="light" effect="solid" />
                    </li>
                ))}
                </ul>
                <div className="footer">
                    <div style={{width: '120px'}}/>
                    <Pages to="/" enabled={!loading} current={page} isLast={isLast}/>
                    <div style={{width: '120px'}}/>
                </div>
            </div>
        );
    }
}
DeeplinkList.contextType = UserContext;

export default DeeplinkList;
