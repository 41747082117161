import {useContext} from 'react';
import {UserContext} from "../context/User";

let RestrictedArea = (props) => {
    const user = useContext(UserContext);
    const {allowedTo,allowedToGroupOnly,allowedIfOrganicsAvailable} = props;
    return ((typeof allowedToGroupOnly !== 'undefined' && allowedToGroupOnly === user.group)
        || (typeof allowedTo !== 'undefined' && user.hasPermission(allowedTo))
        || (typeof allowedIfOrganicsAvailable !== 'undefined' && allowedIfOrganicsAvailable && user.isOrganicsAvailable()))
        ? props.children : null;
};

// [DANGER] Just for initial create
/*RestrictedArea = (props) => {
    return props.children;
};*/
// ~~

export default RestrictedArea;