import React, { Component } from 'react';
import axios from "axios";
import Link from "../../widgets/Link";
import queryString from "query-string";

class DeeplinkStats extends Component {
    state = {
        deeplink_id: 0,
        loading: true,
        stats: []
    };

    componentDidMount() {
        //let params = queryString.parse(this.props.location.search);
        //let a = 3;
        let params = queryString.parse(this.props.location.search);
        let deeplink_id = parseInt(params.id ? params.id : 0);
        if (deeplink_id !== 0) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(deeplink_id);
        }
    }

    async fetchData(deeplink_id) {
        this.setState({loading: true/*, deeplinks: []*/});

        let res = await axios.post('/deeplink_stats', {deeplink_id: deeplink_id});

        if (res.data && Array.isArray(res.data.stats))
            this.setState({loading: false, deeplink_id: deeplink_id, stats: res.data.stats});
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {stats} = this.state;
        //console.log('[QQQ]',deeplinks[0]);
        return (
            <div className="page deeplinks deeplink-stats">
                <h1>Статистика по диплинку</h1>

                <ul>
                    <li className="header" key={0}>
                        <div className="statsTargetLink">
                            Линк
                        </div>
                        <div className="stats2">
                            Количество инсталлов
                        </div>
                    </li>
                    {stats.map((row,index) => (
                        <li key={index}>
                            <div className="statsTargetLink">
                                {row.targetLink}
                            </div>
                            <div className="stats2">
                                {row.count}
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="footer">
                    <div/>
                    <Link to="/"><button className="common text-and-icon secondary"><span className="icon"><i className="arrow left"/></span><span>Назад</span></button></Link>
                    <div/>
                </div>
            </div>
        );
    }
}


export default DeeplinkStats;

