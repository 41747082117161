import React, { Component } from 'react';
import axios from "axios";
import {getResponseErrorsList,pick} from "../../util/util";
import {GameReleaseState} from "../../common/commonTypes";
import Link from "../../widgets/Link";
import {formatPercent} from "../../util/util";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ReactTooltip from 'react-tooltip'
import queryString from "query-string";
import {UserContext, UserGroup} from "../../context/User";

const OTHER_KEY = '__other__';
const POSTBACK_LINK = 'https://pb.solapps.net/{uid}/{event}/';
const FB_EVENTS = [
    { name: 'Регистрация', fbevent: 'register' },
    { name: 'Покупка', fbevent: 'purchase' },
    /*{ name: 'Подписка', fbevent: 'subscribe' },
    { name: 'Начало пробного периода', fbevent: 'start_trial' },
    { name: 'Расход. кредитов', fbevent: 'spent_credits' },*/
];
const POSTBACK_PARAM_OPTIONS = ['s5','s4','sub5','sub_5','uid'];

class CreateNewDeeplink extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            loading: true,
            availableGames: [],
            errors: {},
            postback_support: false,
            postback_uid_param: 's5',
            gameID: 0
            /*pushSupport: null*/
        };
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        if (this.props.location.pathname.startsWith('/editdeeplink')) {
            let deeplink_id = parseInt(params.id ? params.id : 0);
            if (deeplink_id !== 0) {
                //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
                this.setState({edit_mode: true});
                this.fetchEditData(deeplink_id);
            }

        } else {
            const apps_mode = !!params.apps;
            this.setState({apps_mode});
            this.fetchCreateData(apps_mode);
        }
    }

    getUser() {
        return this.context;
    }

    async fetchCreateData(apps_mode) {
        const default_postback_uid_param = this.getUser().getFavData('default_postback_uid_param');
        const extra_state = (default_postback_uid_param && default_postback_uid_param !== '')
            ? { postback_support: true, postback_uid_param: default_postback_uid_param } : { postback_support: false };
        this.setState({...{loading: true},...extra_state});

        if (apps_mode) {
            let [res,res2] = await axios.all([
                axios.post('/newdeeplink_available_apps'),
                /*axios.post('/get_extraChargeForPushSupport'),*/
            ]);

            if (res.data && Array.isArray(res.data)) {
                if (res.data.length > 0) {
                    let games = res.data;
                    let gameID = this.isUniversalLinkAvailable() ? 0 : parseInt(games[0].id);
                    this.setState({...{loading: false, availableGames: games}, ...this.prepareGameState(games,gameID)/* gameLink: this.getGameLink(games, gameID)*/});
                } else {
                    this.setState({loading: false, availableGames: []});
                }
            }
        }

        /*if (res2.data) {
            this.setState({extraChargeForPushSupport: res2.data.extraChargeForPushSupport, extraChargeForPushSupportDisc: res2.data.extraChargeForPushSupportDisc});
        }*/
    }

    isUniversalLinkAvailable = () => true /*this.getUser().isNamingAvailable()*/

    async fetchEditData(deeplink_id) {
        let res = await axios.post('/deeplink_data', {deeplink_id: deeplink_id});

        if (res.data)
            this.setState({loading: false,
                deeplink_id: deeplink_id,
                availableGames: [{id: res.data.game_id, name: res.data.game_name}],
                name: res.data.name, targetLink: res.data.target_link,
                gameID: res.data.game_id,
                postback_support: res.data.postback_support,
                postback_uid_param: res.data.postback_uid_param,
            });
    }


    onSubmit(event) {
        event.preventDefault();

        if (this.busy) {
            console.log('busy');
            return;
        }

        /*if (this.state.pushSupport === null) {
            this.setState({errors: {pushSupport: "Пожалуйста выберите, нужны ли пуши?"}});
            return;
        }*/

        let params = pick(this.state,['name','targetLink','postback_support','postback_uid_param'/*,'pushSupport'*/]);
        if (!params.targetLink) {
            this.setState({errors: {targetLink: 'Укажите целевую ссылку'}});
            return;
        }
        params.targetLink = params.targetLink.trim();

        this.busy = true;
        axios.post(this.state.edit_mode ? '/updatedeeplink' : '/newdeeplink', {
            ...params, ...(this.state.edit_mode ? {deeplink_id: this.state.deeplink_id} : {game_id: this.state.gameID})
        }).then((res) => {
            if (!this.state.edit_mode) {
                let default_postback_uid_param = this.state.postback_support ? this.state.postback_uid_param : '';
                this.getUser().setFavData('default_postback_uid_param', default_postback_uid_param);
            }

            this.props.history.push('/');
            this.busy = false;
        }).catch((err) => {
            this.setState({errors: getResponseErrorsList(err)});
            this.busy = false;
        });
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors) {
            return this.state.errors[param];
        }
        return '';
    }

    onFieldChanged(param) {
        return (e) => this.setState({ errors: '', [param]: e.target.value });
    }

    onPushSupportChange(e) {
        this.setState({ errors: '', pushSupport: e.target.value === "true" });
    }


    getGameLink(games, gameID) {
        if (games.length > 0) {
            const game = games.find(game => game.id===gameID);
            if (game) return game.link;
        }
        return '';
    }

    prepareGameState(games,gameID) {
        let game = games.find(game => game.id===gameID);
        return { gameID: gameID,
            gameExtraInfo: game ? game.extraInfo : '',
            /*gamePushSupport: game ? game.pushSupport : false,*/
            gameReleased: game ? game.released : 0,
            gameName: game ? game.name : '',
            gameDiscount: (game && game.priceRate !== 1.0) ? formatPercent(1.0 - game.priceRate) : ''};
    }

    onGameChanged() {
        return (e) => {
            let gameID = parseInt(e.target.value);
            this.setState({ ...{ errors: '', pushSupport: null}, ...this.prepareGameState(this.state.availableGames,gameID)} );
            /*, gameLink: this.getGameLink(this.state.availableGames, gameID) */
        }
    }

    onPostbackParamSelected(e) {
        const val = e.target.value;
        this.setState({ postback_uid_param: val === OTHER_KEY ? '' : val });
    }

    onPostbackParamChanged(e) {
        this.setState({ postback_uid_param: e.target.value });
    }

    render() {
        const renderError = (fieldName) => (
            <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>
        );

        let {availableGames,targetLink,postback_support,postback_uid_param,edit_mode,apps_mode,
            gameReleased,gameExtraInfo,gameDiscount} = this.state;
        const checkPostbackSupport = () => { this.setState({postback_support: !postback_support}) };

        const otherPostbackParamSelected = !POSTBACK_PARAM_OPTIONS.some(v => v === postback_uid_param);

        const simpleColors = !this.getUser().hasPermission(UserGroup.MODERATOR);

        return (
            <div className="page">
                <h1>{ edit_mode ? 'Изменить' : 'Создать'} диплинк</h1>
                <form className="wide" onSubmit={this.onSubmit.bind(this)} noValidate>
                    <ul>
                        {apps_mode &&
                        <li className="field">
                            <div><label htmlFor="name">Приложение</label></div>
                            <div><select id="game" name="game" onChange={this.onGameChanged()} required disabled={this.state.edit_mode}
                                         className={GameReleaseState.getColorClass(gameReleased, "", simpleColors)}>
                                {/*[UNIVERSAL]*/ this.isUniversalLinkAvailable() &&
                                    <option key={0} value={0} className={"text-default"}>Универсальный линк (any app)</option>
                                }
                                {availableGames.map(game => {
                                    return <option key={game.id} value={game.id}
                                            className={GameReleaseState.getColorClass(game.released, "text-default", simpleColors)}>{game.name}</option>
                                })}
                                </select>
                            </div>
                            { gameReleased === GameReleaseState.BANNED_BY_FB &&
                            <div style={{fontSize: '14px', fontWeight: 'bold', paddingLeft: '8px', display: 'block'}} className={'gray'}>Прилка забанена в FB</div>
                            }
                            { gameReleased === GameReleaseState.RESTRICTED_99_CABS &&
                            <div style={{fontSize: '12px', paddingLeft: '8px', color: '#c17a00', display: 'block'}}>Прилка имеет ограничение 99 по кабам в FB</div>
                            }
                            { gameExtraInfo &&
                            <div style={{fontSize: '12px', paddingLeft: '8px', color: '#c17a00', display: 'block'}}>{gameExtraInfo}</div>
                            }
                            { gameDiscount &&
                            <div style={{fontSize: '12px', paddingLeft: '8px', color: '#328f42', display: 'block'}}>
                                Приложение со скидкой за инсталлы: <b>-{gameDiscount}</b></div>
                            }
                            {/*this.state.gameLink &&
                                <div style={{'font-size': '12px', 'padding-left': '8px'}}><a href={this.state.gameLink} target="_blank" rel="noopener noreferrer">{this.state.gameLink}</a></div>
                            */}
                            {renderError("game")}
                        </li>}
                        <li className="field">
                            <div><label htmlFor="name">Название кампании</label></div>
                            <div className="tip">Необязательно, для вашего удобства</div>
                            <div><input id="name" name="name" type="text" value={this.state.name} onChange={this.onFieldChanged("name")} required/></div>
                            {renderError("name")}
                        </li>
                        <li className="field">
                            <div><label htmlFor="targetLink">Целевая ссылка (target link)<span className="required"/></label></div>
                            <div className="tip">Укажите ссылку, куда в результате должен прийти пользователь</div>
                            <div><textarea id="targetLink" className="wide" name="targetLink" maxLength="512" value={targetLink} onChange={this.onFieldChanged("targetLink")} required/></div>
                            <div className="tip"><p>Доступные макросы (опционально): <span style={{}}>{'{_dev_id_}'}</span> - уникальное ID устройства (для постбеков и т д).
                                    При включенных постбеках он будет добавлятся сам. <span style={{}}>{'{_bund_id_}'}</span> - ID прилки в
                                маркете.  <span style={{/*fontWeight: 'bold'*/}}>{'{_camp_id_}'}</span> - ID кампании с апсфлаера (его партнерок), если инсталл прошел по неймингу</p>
                            </div>
                            {renderError("targetLink")}
                        </li>
                        {/*<li className="field">
                            <div><label htmlFor="pushSupport">Поддержка пушей<span className="required"/></label></div>
                            {this.state.extraChargeForPushSupport && this.state.gamePushSupport ?
                            <div className="tip">Должны ли поддерживаться пуши для инсталлов по данному диплинку</div>
                            : <div className="tip">Приложение {this.state.gameName} не поддерживает пуши</div>}
                            <div>
                                <label>
                                    <input type="radio" value="false"
                                           checked={this.state.pushSupport === false}
                                           onChange={this.onPushSupportChange.bind(this)}/>
                                    {this.state.extraChargeForPushSupport && this.state.gamePushSupport ?
                                        'Без пушей' : 'Без пушей, подтверждаю'}
                                </label>
                                {this.state.extraChargeForPushSupport && this.state.gamePushSupport &&
                                <label>
                                    <input type="radio" value="true"
                                           checked={this.state.pushSupport === true}
                                           onChange={this.onPushSupportChange.bind(this)}/>
                                    С поддержкой пушей
                                    (+{formatCurrency(this.state.extraChargeForPushSupport)} за инсталл)
                                </label>
                                }
                            </div>
                            {renderError("pushSupport")}
                        </li>*/}
                        <li className="field">
                            <div><label>Оптимизация</label></div>
                            <div className="checkbox" onClick={checkPostbackSupport}>
                                <div className="ch"><input id="postback_support" type="checkbox" checked={postback_support} onChange={()=>{}}/></div><label className="smallerText">Включить постбеки</label>
                            </div>
                            {postback_support ?
                            <div>
                                <div className="inRow leftAlign">
                                    <label className="smallerText">Параметр передачи ID устройства в трекер:</label>
                                    <select className="select smallerText" value={otherPostbackParamSelected ? OTHER_KEY : postback_uid_param}
                                            onChange={this.onPostbackParamSelected.bind(this)}>
                                        {POSTBACK_PARAM_OPTIONS.map(v => <option key={v} value={v}>{v}</option> )}
                                        <option key={OTHER_KEY} value={OTHER_KEY}>другой:</option>
                                    </select>
                                    {otherPostbackParamSelected &&
                                    <input className='otherPostbackParam' value={postback_uid_param} onChange={this.onPostbackParamChanged.bind(this)}/>
                                    }
                                </div>

                                <div className="leftAlign">
                                    <label className="tip">(не используйте его для других целей, и убедитесь что он правильно принимается трекером/партнеркой)</label>
                                </div>

                                <div className="inRow leftAlign" style={{marginTop: '7px'}}>
                                    <label className="smallerText" >Активируйте один или несколько постбеков в трекере (или партнерке):</label>
                                </div>
                                <div className='smallTable' style={{margin: "10px 6px 4px"}}>
                                    {FB_EVENTS.map(e => {
                                        const link = POSTBACK_LINK.replace('{event}', e.fbevent).replace('{uid}', `{${postback_uid_param}}`);
                                        return (<div key={e.fbevent}>
                                            <div className='header' style={{width: '150px'}}>{e.name}</div>
                                            <div className="itemFill" style={{paddingTop: '1px'}}>
                                                <CopyToClipboard text={link}>
                                                    <button type="button" className="copy-to-clipboard" data-tip="Скопировать" data-for={'tooltip_' + e.fbevent}/>
                                                </CopyToClipboard>
                                                {link}
                                            </div>
                                        </div>);
                                    })}
                                </div>
                                {renderError("postback_uid_param")}
                                {FB_EVENTS.map(e =>
                                    <div key={e.fbevent}>
                                        <ReactTooltip id={'tooltip_'+e.fbevent} delayShow={100} type="light" effect="solid" />
                                    </div>
                                )}

                            </div>
                            : null}
                        </li>
                        <li className="submit inCol">
                            <div className="submit inCol">
                                {this.state.edit_mode &&
                                <div className='tip yellow' style={{marginBottom: '5px'}}>Будьте внимательны при изменении параметров диплинка на активных кампаниях.
                                    <br/>При изменении параметры вступят в силу в течении 5 минут для новых инсталлов</div>
                                }
                                <div><button className="common">{ edit_mode ? <span>Изменить</span> : <span>Создать</span>}</button></div>
                            </div>
                        </li>
                        {renderError("common")}
                    </ul>
                </form>
                <div className="footer">
                    <div/>
                    <Link to="/"><button className="common text-and-icon secondary"><span className="icon"><i className="arrow left"/></span><span>Назад</span></button></Link>
                    <div/>
                </div>
            </div>
        );
    }
}
CreateNewDeeplink.contextType = UserContext;

export default CreateNewDeeplink;

