import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";
import {GameReleaseState} from "../../common/commonTypes";
import Link from "../../widgets/Link";
import {UserContext, UserGroup} from "../../context/User";
import RestrictedArea from "../../wrappers/RestrictedArea";

class AdminGameStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            platforms: [],
            data: {}
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/admin_game_install_stats');

        if (res.data)
            this.setState({loading: false, platforms: res.data.platforms, data: res.data});
    }

    render() {
        const { loading, platforms, data } = this.state;
        platforms.forEach( p => {
            p.tables = data ? [
                {name: 'По диплинкам', data: data.deeplinkInstalls.filter(g => g.platform_id === p.id)},
                {name: 'Органика', data: data.organicInstalls.filter(g => g.platform_id === p.id)}
            ] : [];
        });

        let install_count = (count) => {
            if (isNaN(count))
                return <div className="gray">0</div>;
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        let game_name = (name,released) => {
            if (released === GameReleaseState.BANNED_ON_MARKET || released === GameReleaseState.NOT_RELEASED)
                return <div className="red">{name}</div>;
            if (released === GameReleaseState.BANNED_BY_FB)
                return <div className="gray">{name}</div>;
            if (released === GameReleaseState.RESTRICTED_99_CABS)
                return <div className="yellow">{name}</div>;
            return name;
        };

        const showPlatformNames = this.getUser().hasPermission(UserGroup.MODERATOR);

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page">
                <h1>Статистика по приложениям</h1>

                {loading &&
                    <div>
                        <div className="long-loading"/>
                    </div>
                }

                {!loading && platforms.map(platform =>
                    platform.tables.map((table, index) =>
                        <div key={index}>
                            <h2>{(showPlatformNames ? `Платформа ${platform.name}. ` : '') + table.name}</h2>
                            <section className="table-data compact wide">
                                <header>
                                    <div className="col" style={{'flexBasis':'17%'}}>Приложение</div>
                                    <div className="col">За 24 часа</div>
                                    <div className="col">За сегодня</div>
                                    <div className="col">За вчера</div>
                                    <div className="col">За позавчера</div>
                                    <div className="col">Всего</div>
                                    <div className="col smallText">Не доступные<br/>GEO</div>
                                </header>
                                {table.data && table.data.map(row => {
                                    const storeInfo = row.store_info ? JSON.parse(row.store_info) : {};
                                    return <div className="row">
                                        <div className="col" style={{'flexBasis': '17%'}}>
                                            <div className={row.released ? '' : 'gray'}><Link className="soft"
                                                                                              to={`/admin/singlegamestats/?id=${row.id}`}>{game_name(row.name, row.released)}</Link>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className={(!row.released || row.total_installs > 0) ? 'smallerText' : 'verySmallText'}>{
                                                (row.released === GameReleaseState.NOT_RELEASED || row.total_installs > 0)
                                                    ? install_count(row._24h_change) : 'Прилка готова и ждет тестовых инсталлов!'
                                            }</div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="smallerText">{row.total_installs > 0 ? install_count(row.today_change) : ''}</div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="smallerText">{row.total_installs > 0 ? install_count(row.day_ago_change) : ''}</div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="smallerText">{row.total_installs > 0 ? install_count(row._2days_ago_change) : ''}</div>
                                        </div>
                                        <div className="col">
                                            <div className="smallText">{row.total_installs}</div>
                                        </div>
                                        <div className="col">
                                            <div className="smallerText strikethrough gray">{storeInfo.notAvailableGeo}</div>
                                        </div>
                                    </div>
                                })}
                            </section>
                        </div>
                    )
                )}
            </div>
            </RestrictedArea>
        );
    }
}
AdminGameStats.contextType = UserContext;

export default AdminGameStats;
