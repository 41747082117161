import React, { Component } from 'react';
import axios from "axios";
import RestrictedArea from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {formatClientName, formatCurrency, formatDateTime, formatPercent} from "../../util/util";
import './cabinet.css';
import BasePage from "../base/BasePage";
import Pages from "../../widgets/Pages";

class Referrals extends BasePage {
    state = {
        loading: true,
        loadingHistory: true,
        page: 1
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/get_referral_data');

        if (res.data) {
            this.setState({loading: false, referral_reward_rate: res.data.referral_reward_rate, referrals: res.data.referrals});

            if (res.data.referrals.length > 0) {
                this.fetchHistory(this.state.page);
            }
        }
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/get_referral_finance_history', {page: page});

        if (res.data && Array.isArray(res.data.referralFinanceHistory))
            this.setState({loadingHistory: false, page: page, isLast: res.data.isLast, referralFinanceHistory: res.data.referralFinanceHistory});
    }

    renderPage() {
        const { loadingHistory, referral_reward_rate, referrals, referralFinanceHistory, page, isLast } = this.state;

        let total_reward_from_all_referrals;
        if (referrals) total_reward_from_all_referrals = formatCurrency(
            referrals.reduce((sum,r) => (sum + (r.total_reward_from_referral ? parseFloat(r.total_reward_from_referral) : 0.0)),0.0)
        );

        return (
            <RestrictedArea allowedTo={UserGroup.SUPERUSER}>
            <div className="page">
                <h1>Реффералы</h1>

                { referrals &&
                <div className="info">
                    {referrals.length === 0 ?
                    <p>
                        У Вас есть возможность пригласить других участников в систему и зарабатывать от <b>5%</b> до <b>20%</b> от их денежных поступлений.
                        <br/>Чтобы зарегистрировать реферала, свяжитесь с Администрацией.
                    </p>
                    :
                    <div>
                        <ul className='info'>
                            <li>
                                <div className='leftAlign'><span className={'smallerText'}>Ваш доход от поступления средств
                                    рефералов:</span>  <b>{formatPercent(referral_reward_rate)}</b></div>
                            </li>
                            <li>
                                <div className='leftAlign'><span className={'smallerText'}>Всего заработано:</span> <b>{total_reward_from_all_referrals}</b></div>
                            </li>
                        </ul>

                        <div style={{marginTop: '30px'}}>
                            <h2>Мои рефералы:</h2>

                            <section className="table-data compact">
                                <header>
                                    <div className="col">Время регистрации</div>
                                    <div className="col">Реферал</div>
                                    <div className="col"><div className="smallText">Общий оборот<br/>реферала</div></div>
                                    <div className="col">
                                        <div className="smallerText">Инсталлов обработано</div>
                                        <div className="smallText">(инсталлов всего)</div>
                                    </div>
                                    <div className="col"><div className="smallText">Общий заработок<br/>от реферала</div></div>
                                </header>
                                {referrals.map(r => (
                                    <div className="row">
                                        <div className="col smallText">{formatDateTime(r.reg_time)}</div>
                                        <div className="col">{r.name}</div>
                                        <div className="col">
                                            <div className="smallText">{formatCurrency(r.total_turnover)}</div>
                                        </div>
                                        <div className="col">
                                            <div>{r.total_installs}</div>
                                            <div className="smallText">({r.total_installs_recalc})</div>
                                        </div>
                                        <div className="col">
                                            <div>{formatCurrency(r.total_reward_from_referral ? r.total_reward_from_referral : 0)}</div>
                                        </div>
                                    </div>
                                ))}
                            </section>
                        </div>
                        { !loadingHistory &&
                        <div style={{marginTop: '30px'}}>
                            <h2>Финансовая история</h2>

                            <section className="table-data compact" style={{width: '70%'}}>
                                <header>
                                    <div className="col">Время</div>
                                    <div className="col">Реферал</div>
                                    <div className="col">Сумма</div>
                                </header>
                                {referralFinanceHistory && referralFinanceHistory.map((hist,i) => (
                                    <div key={i} className="row">
                                        <div className="col smallText">{formatDateTime(hist.time)}</div>
                                        <div className="col smallerText">{hist.referral}</div>
                                        <div className="col">{formatCurrency(hist.amount)}</div>
                                    </div>
                                ))}
                            </section>
                            <div className="footer">
                                <Pages style={{margin: '0 auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={page} isLast={isLast}/>
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
                }
            </div>
            </RestrictedArea>
        );
    }
}



export default Referrals;
