import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './css/style.css';
import './css/style2.css';
import AdminPortal from "./pages/admin/AdminPortal";
import {User, UserGroup} from "./context/User";
import MainMenu from "./widgets/MainMenu";
import RestrictedArea from "./wrappers/RestrictedArea";
import DeeplinkList from "./pages/deeplink/DeeplinkList";
import CreateNewDeeplink from "./pages/deeplink/CreateNewDeeplink";
import DeeplinkStats from "./pages/deeplink/DeeplinkStats";
import CabinetPage from "./pages/cabinet/CabinetPage";
import WarnTopPanel from "./widgets/WarnTopPanel";
import PushList from "./pages/push/PushList";
import CreateNewPush from "./pages/push/CreateNewPush";
import OrganicsList from "./pages/organics/OrganicsList";
import OrganicsStats from "./pages/organics/OrganicsStats";
import StartPage from "./pages/StartPage";
import PageContainer from "./pages/PageContainer";

class App extends Component {
  render() {
    return (
        <User>
            <RestrictedArea allowedTo={UserGroup.USER}>
                <BrowserRouter>
                    <MainMenu/>
                    <WarnTopPanel/>
                    <Switch>
                        <Route exact path='/' component={DeeplinkList}/>
                        <Route path='/newdeeplink' component={CreateNewDeeplink}/>
                        <Route path='/editdeeplink' component={CreateNewDeeplink}/>
                        <Route path='/organics' component={OrganicsList}/>
                        <Route path='/organicstats' component={OrganicsStats}/>
                        {/*[PUSH]*/ <Route path='/push' component={PushList}/>}
                        <Route path='/newpush' component={CreateNewPush}/>
                        <Route path='/stats' component={DeeplinkStats}/>
                        <Route path='/cabinet' component={CabinetPage}/>
                        <Route path='/admin' component={AdminPortal}/>
                    </Switch>
                </BrowserRouter>
            </RestrictedArea>
            <RestrictedArea allowedToGroupOnly={UserGroup.GUEST}>
                <StartPage/>
            </RestrictedArea>
        </User>
    );
  }
}

export default App;
