import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";

class GameStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            data: []
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/game_install_stats');

        if (res.data && Array.isArray(res.data))
            this.setState({loading: false, data: res.data});
    }

    render() {
        const { data } = this.state;

        let install_count = (count) => {
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        return (
            <div className="page">
                <h1>Статистика по приложениям</h1>

                <h2>Статистика инсталлов</h2>
                <section className="table-data compact wide">
                    <header>
                        <div className="col" style={{'flexBasis':'17%'}}>Приложение</div>
                        <div className="col">За 24 часа</div>
                        <div className="col">За сегодня</div>
                        <div className="col">За вчера</div>
                        <div className="col">За позавчера</div>
                        <div className="col">Всего</div>
                    </header>
                    {data.map(row => (
                        <div className="row">
                            <div className="col" style={{'flexBasis':'17%'}}>
                                <div>{row.name}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(row._24h_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(row.today_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(row.day_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(row._2days_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallText">{row.total_installs}</div>
                            </div>
                        </div>
                    ))}
                </section>
            </div>
        );
    }
}

export default GameStats;
