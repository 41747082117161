
const UserGroup = {
    GUEST: 0,
    USER: 1,
    SUPERUSER: 2,
    PLATFORM_ADMIN: 3,
    MODERATOR: 4,
    ADMIN: 5,
    toString(userGroup) {
        switch (userGroup) {
            case UserGroup.GUEST: return "Гость";
            case UserGroup.USER: return "Пользователь";
            case UserGroup.SUPERUSER: return "Пользователь"; // Руководитель
            case UserGroup.PLATFORM_ADMIN: return "Администратор платформы"; // Руководитель
            case UserGroup.MODERATOR: return "Модератор";
            case UserGroup.ADMIN: return "Администратор";
            default: return "Неизвестный";
        }
    },
    hasPermission(userGroup, resourceAccessLevel) {
        return userGroup >= resourceAccessLevel;
    },
    notInGroups(userGroup, groups) {
        return !groups.some(g => g === userGroup);
    }
};

const EventNames = {
    UPDATE_DATA: 'e-update-data'
};

const PUSH_SOUNDS = [
    {id: 'default', name: 'Обычный'},
    {id: 'ching', name: 'Дзынь'},
    {id: 'coin', name: 'Монетка'},
    {id: 'many_coins', name: 'Много монет'},
];

const FBCabState = {
    ACTIVE: 1,
    TO_BE_ADDED: 2,
    TO_BE_REMOVED: 3,
    FAILED_TO_ADD: 12,
    FAILED_TO_REMOVE: 13
};

const OrganicsState = {
    DISABLED: 0,
    ACTIVE: 1,
    toString(organicsState) {
        switch (organicsState) {
            case OrganicsState.DISABLED: return "DISABLED";
            case OrganicsState.ACTIVE: return "ACTIVE";
            default: return "Unknown";
        }
    }
};

const GameReleaseState = {
    NOT_RELEASED: 0,
    RELEASED: 1,
    BANNED_BY_FB: 2,
    BANNED_ON_MARKET: 3,
    RESTRICTED_99_CABS: 4,
    getColorClass(releaseState, defaultColor = "", simplified = false) {
        switch (releaseState) {
            case GameReleaseState.BANNED_BY_FB: return "gray";
            case GameReleaseState.BANNED_ON_MARKET: return "red";
            case GameReleaseState.RESTRICTED_99_CABS: return simplified ? defaultColor : "yellow";
            default: return defaultColor;
        }
    }
};

const NodeIPState = {
    NEW: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    toString(trafficState) {
        switch (trafficState) {
            case NodeIPState.NEW: return "NEW";
            case NodeIPState.ACTIVE: return "ACTIVE";
            case NodeIPState.INACTIVE: return "INACTIVE";
            default: return "Unknown";
        }
    }
};

const Token = {
    USDT_TRC20: 'USDT_TRC20',
    toString(token) {
        switch (token) {
            case Token.USDT_TRC20: return "USDT (TRC20)";
            default: return "Unknown";
        }
    },
    getDepositTypeID(token) {
        switch (token) {
            case Token.USDT_TRC20: return 6;
            default: return 0;
        }
    }
};

const DepositRequestStatus = {
    PENDING: 0,
    COMPLETED: 1,
    EXPIRED: 2,
    PROCESSING: 3,
    toString(status) {
        switch (status) {
            case DepositRequestStatus.PENDING: return "PENDING";
            case DepositRequestStatus.COMPLETED: return "COMPLETED";
            case DepositRequestStatus.EXPIRED: return "EXPIRED";
            case DepositRequestStatus.PROCESSING: return "PROCESSING";
            default: return "Unknown";
        }
    }
};

module.exports = {
    UserGroup,
    EventNames,
    PUSH_SOUNDS,
    FBCabState,
    OrganicsState,
    GameReleaseState,
    NodeIPState,
    Token,
    DepositRequestStatus
};