
function getResponseErrorsList(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return {"common": "Неизвесткая ошибка"};

    if (!data.errors)
        return {"common": "Ошибка сервера"};

    if (Array.isArray(data.errors))
        return data.errors.reduce((errors, err) => ({...errors, ...{[err.param]: err.msg}}), {});

    if (typeof data.errors === 'object')
        return data.errors;

    return {"common": data.errors};
}

function getResponseError(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return "Неизвесткая ошибка";

    if (!data.errors)
        return "Ошибка сервера";

    return data.errors;
}

export * from '../common/commonUtil';
export {
    getResponseErrorsList,
    getResponseError
}
