import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency, isEmpty} from "../../util/util";
import {CopyToClipboard} from "react-copy-to-clipboard";

class CompanyStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            loadingFD: true,
            loading: true,
            data: {}
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchFinanceData();
        this.fetchData();
    }

    async fetchFinanceData() {
        this.setState({loadingFD: true});

        let res = await axios.post('/get_finance_data');

        if (res.data)
            this.setState({loadingFD: false, financeData: res.data});
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/users_stats');

        if (res.data)
            this.setState({loading: false, data: res.data});
    }

    render() {
        const { financeData, data } = this.state;

        let install_count = (count) => {
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        const calc_total = (rows, total_arr, parseFunc) =>  {
            if (rows.length <= 1) return;
            rows.forEach(row => {
                for (let key in row) {
                    if (key in total_arr)
                        total_arr[key] += parseFunc(row[key]);
                    else
                        total_arr[key] = parseFunc(row[key]);
                }
            });
        }
        let install_stats_total = {};
        let finance_stats_total = {};
        if (data) {
            if (data.install_stats) calc_total(data.install_stats, install_stats_total, parseInt);
            if (data.finance_stats) calc_total(data.finance_stats, finance_stats_total, parseFloat);
        }

        return (
            <div className="page">
                <h1>Статистика компании</h1>

                <h2>Тарифы:</h2>
                {financeData &&
                <ul className='info'>
                    <li>
                        <div className='smallTable' style={{marginRight: "12px"}}>
                            <div>
                                <div className='header' style={{width: '120px', justifyContent: 'center'}}>Цена инсталла</div>
                                <div className="header" style={{width: '120px', justifyContent: 'center'}}>при положительном балансе</div>
                                <div className="header" style={{width: '120px', justifyContent: 'center'}}>за кредитные средства</div>
                            </div>
                            <div>
                                <div className='header' style={{width: '120px', justifyContent: 'right'}}>Источник Facebook:</div>
                                <div className="itemFill" style={{width: '120px', fontWeight: 'bold'}}>{
                                    formatCurrency(financeData.installPriceWithPositiveBalance,3)
                                }</div>
                                <div className="itemFill" style={{width: '120px'}}>{
                                    formatCurrency(financeData.installPriceWithNegativeBalance,3)
                                }</div>
                            </div>
                            <div>
                                <div className='header' style={{width: '120px', justifyContent: 'right'}}>Другие источники:</div>
                                <div className="itemFill" style={{width: '120px', fontWeight: 'bold'}}>{
                                    formatCurrency(financeData.appsflInstallPriceWithPositiveBalance,3)
                                }</div>
                                <div className="itemFill" style={{width: '120px'}}>{
                                    formatCurrency(financeData.appsflInstallPriceWithNegativeBalance,3)
                                }</div>
                            </div>
                        </div>
                    </li>
                    {/*<li>
                        <div className='rightAlign smallerText'>Поддержка пушей на устройстве при инсталле при положительном балансе:</div>
                        <div><b>{formatCurrency(financeData.extraChargeForPushSupportDisc,3)}</b></div>
                    </li>
                    <li>
                        <div className='rightAlign smallerText'>Поддержка пушей на устройстве при инсталле за кредитные средства:</div>
                       <div><b>{formatCurrency(financeData.extraChargeForPushSupport,3)}</b></div>
                    </li>*/}
                    {parseFloat(financeData.credit_limit) > 0.0 &&
                    <li>
                        <div className='rightAlign smallerText'>Ваш кредитный лимит:</div>
                        <div><b>{formatCurrency(financeData.credit_limit)}</b></div>
                    </li>
                    }
                </ul>
                }

                <h2>Инсталлы</h2>
                {data && data.install_stats ?
                    <section className="table-data compact wide">
                        <header>
                            <div className="col" style={{'flexBasis': '13%'}}>Пользователь</div>
                            <div className="col">За 24 часа</div>
                            <div className="col">За сегодня</div>
                            <div className="col">За вчера</div>
                            <div className="col">За позавчера</div>
                            <div className="col">Всего</div>
                        </header>
                        {data.install_stats.map(row => (
                            <div className="row">
                                <div className="col" style={{'flexBasis': '13%'}}>
                                    <div className="smallerText">{row.name}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{install_count(row._24h_change)}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{install_count(row.today_change)}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{install_count(row.day_ago_change)}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{install_count(row._2days_ago_change)}</div>
                                </div>
                                <div className="col">
                                    <div className="smallText">{row.total_installs}</div>
                                </div>
                            </div>
                        ))}
                        { !isEmpty(install_stats_total) &&
                        <footer>
                            <div className="col" style={{'flexBasis': '13%'}}>
                                <div className="smallerText">Итого:</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(install_stats_total._24h_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(install_stats_total.today_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallerText">{install_count(install_stats_total.day_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div
                                    className="smallerText">{install_count(install_stats_total._2days_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallText">{install_stats_total.total_installs}</div>
                            </div>
                        </footer>
                        }
                    </section>
                : <div><div className="long-loading"/></div>}

                <h2>Расходы</h2>
                {data && data.finance_stats ?
                    <section className="table-data compact wide">
                        <header>
                            <div className="col" style={{'flexBasis':'13%'}}>Пользователь</div>
                            <div className="col">За 24 часа</div>
                            <div className="col">За сегодня</div>
                            <div className="col">За вчера</div>
                            <div className="col">За позавчера</div>
                            <div className="col">Всего</div>
                        </header>
                        {data.finance_stats.map(row => (
                            <div className="row">
                                <div className="col" style={{'flexBasis':'13%'}}>
                                    <div className="smallerText">{row.name}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(row._24h_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(row.today_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(row.day_ago_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(row._2days_ago_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallText">{formatCurrency(parseFloat(row.total_amount))}</div>
                                </div>
                            </div>
                        ))}
                        { !isEmpty(finance_stats_total) &&
                            <footer>
                                <div className="col" style={{'flexBasis':'13%'}}>
                                    <div className="smallerText">Итого:</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(finance_stats_total._24h_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(finance_stats_total.today_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(finance_stats_total.day_ago_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallerText">{formatCurrency(parseFloat(finance_stats_total._2days_ago_change))}</div>
                                </div>
                                <div className="col">
                                    <div className="smallText">{formatCurrency(parseFloat(finance_stats_total.total_amount))}</div>
                                </div>
                            </footer>
                        }
                    </section>
                : <div><div className="long-loading"/></div>}
            </div>
        );
    }
}

export default CompanyStats;
