import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";
import {UserContext, UserGroup} from "../../context/User";
import RestrictedArea from "../../wrappers/RestrictedArea";
import AdminGameStats from "./ServerSettings";

class ClientStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    isAdmin() {
        return this.getUser().hasPermission(UserGroup.ADMIN);
    }

    isPlatformAdmin() {
        return this.getUser().isInGroup(UserGroup.PLATFORM_ADMIN);
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/client_stats');

        if (res.data)
            this.setState({loading: false, platforms: res.data.platforms, clients: res.data.clients});
    }

    render() {
        const { loading, clients, platforms } = this.state;

        const installsSpeedIcon = (installs_6h, installs_24h, useEmptyIcon) => {
            const rate = 4 * installs_6h / installs_24h;
            let iconClass= "stableIcon"; /* useEmptyIcon ? "stableIcon" : null;*/
            if (rate >= 2.0) iconClass = "up2Icon";
            else if (rate >= 1.1) iconClass = "upIcon";
            else if (rate <= 0.5) iconClass = "down2Icon";
            else if (rate <= 0.9) iconClass = "downIcon";
            return iconClass ? <div className={iconClass}/> : null;
        }

        const isAdmin = this.isAdmin();
        const isPlatformAdmin = this.isPlatformAdmin();

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page">
                <h1>Статистика по клиентам</h1>

                {!loading && platforms.map(platform =>
                    <section key={platform.id} className="table-data compact wide">
                        <RestrictedArea allowedTo={UserGroup.MODERATOR}>
                        <header><div className="col"><b>Платформа {platform.name}</b></div></header>
                        </RestrictedArea>
                        <header>
                            <div className="col" style={{'flexBasis':'17%'}}>Клиент</div>
                            <div className="col">Баланс</div>
                            <div className="col">Цена инсталла<div className="smallText">(в данный момент)</div></div>
                            {isAdmin &&
                            <div className="col">Общий оборот</div>
                            }
                            <div className="col separateData"><div className="smallerText">Пользователь</div></div>
                            {(isAdmin || isPlatformAdmin) ?
                            <div className="col separateData"><div className="smallerText">Инсталлов всего</div>
                                <div className="smallText">(<span className="green">за 24ч</span>)</div></div>
                                :
                            <div className="col separateData"><div>Инсталлов за 24ч</div></div>
                            }
                        </header>
                        {clients.filter(c => c.platform_id === platform.id).map(row => (
                            <div className="row">
                                <div className="col" style={{'flexBasis': '17%'}}>
                                    <div>{row.name}</div>
                                    <div className="smallText">{row.contact_person}</div>
                                </div>
                                <div className="col">
                                    {formatCurrency(row.balance)}
                                </div>
                                <div className="col">
                                    <div className="smallText">
                                        <div>{formatCurrency(row.install_price)}</div>
                                    </div>
                                </div>
                                {isAdmin &&
                                <div className="col">
                                    <div className="smallText">
                                        <div>{formatCurrency(row.total_turnover)}</div>
                                    </div>
                                </div>
                                }
                                <div className="col separateData">
                                    <div className="smallerText">{row.login}</div>
                                </div>
                                {(isAdmin || isPlatformAdmin) ?
                                <div className="col separateData">
                                    <div className="smallerText">{row.total_installs}</div>
                                    <div className="smallText">
                                        {row.installs_24h > 0 &&
                                            <div>({installsSpeedIcon(row.installs_6h,row.installs_24h)
                                                }<span className="green">{row.installs_24h}</span>)</div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="col separateData">
                                    <div className="smallerText">
                                        {row.installs_24h > 0 ?
                                            <div>{installsSpeedIcon(row.installs_6h,row.installs_24h,true)
                                            }<span className="green">{row.installs_24h}</span></div>
                                            : 0
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        ))}
                    </section>
                )}

                {loading &&
                    <div>
                        <div className="long-loading"/>
                    </div>
                }
            </div>
            </RestrictedArea>
        );
    }
}
ClientStats.contextType = UserContext;

export default ClientStats;
