import React from 'react';
import Link from "./Link";

let Pages = (props) => {
    const current = props.current;
    let to = props.to;
    let handler = props.handler;
    if (to) to += (to.indexOf('?')===-1) ? '?' : '&';
    const isLast = props.isLast;
    const isFirst = current === 1;
    const enabled = props.enabled;

    let LinkEx = (props) => {
        const {children,enabled,to} = props;
        return enabled
            ? <Link to={to}>{children}</Link>
            : <Link onClick={e => e.preventDefault()} to={to}>{children}</Link>;
    };

    let buttonLeft = (page) => <button onClick={handler ? handler.bind(null, page) : null} className="common icon secondary"><i className="arrow left"/></button>;
    let buttonRight = (page) => <button onClick={handler ? handler.bind(null, page) : null} className="common icon secondary"><i className="arrow right"/></button>;

    return (
        <div className="pages" style={props.style}>
            <div className="prev">
                { !isFirst && ( to ?
                    <LinkEx enabled={enabled} to={`${to}page=${current - 1}`}>{buttonLeft()}</LinkEx>
                    : buttonLeft(current - 1)
                )}
            </div>
            { (!(isFirst && isLast)) && <div className="current"><span>{current}</span></div> }
            <div className="next">
                { !isLast && ( to ?
                    <LinkEx enabled={enabled} to={`${to}page=${current + 1}`}>{buttonRight()}</LinkEx>
                    : buttonRight(current + 1)
                )}
            </div>
        </div>
    );
};

export default Pages;