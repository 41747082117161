import React from 'react';
import {NavLink, Route} from "react-router-dom";
import ClientStats from "./ClientStats";
import AdminGameStats from "./AdminGameStats";
import AdminSingleGameStats from "./AdminSingleGameStats";
import CreateNewClient from "./CreateNewClient";
import {UserGroup} from "../../context/User";
import RestrictedArea from "../../wrappers/RestrictedArea";
import ManageGames from "./ManageGames";
import DepositFunds from "./DepositFunds";
import EditClientSettings from "./EditClientSettings";
import ServerSettings from "./ServerSettings";
import OptimizeGames from "./OptimizeGames";

const adminPages = [
    { path: '/admin/clientstats', icon: 'bar-chart', menu: 'Статистика по клиентам', component: ClientStats, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: '/admin/gamestats', icon: 'bar-chart', menu: 'Статистика по прилкам', component: AdminGameStats, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: '/admin/newclient', icon: 'user', menu: 'Новый клиент', component: CreateNewClient, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: '/admin/editclient', icon: 'user', menu: 'Управление клиентами', component: EditClientSettings, accessLevel: UserGroup.MODERATOR },
    { path: '/admin/managegames', icon: 'gp-app', menu: 'Управление приложениями', component: ManageGames, accessLevel: UserGroup.PLATFORM_ADMIN },
    /*{ path: '/admin/optimize', icon: 'opt', title: 'Оптимизация', component: OptimizeGames, accessLevel: UserGroup.ADMIN },*/
    { path: '/admin/deposit', icon: 'money-with-wings', menu: 'Пополнить счет клиенту', component: DepositFunds, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: '/admin/serversettings', icon: 'gear', menu: 'Установки серверов', component: ServerSettings, accessLevel: UserGroup.MODERATOR },

    { path: '/admin/singlegamestats', component: AdminSingleGameStats, accessLevel: UserGroup.PLATFORM_ADMIN },
];

let AdminPortal = () => (
    <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
        <div className="right-menu-page">
            <div className="menu">
                <div className="menu-list">
                    {adminPages.map(menuItem => menuItem.menu &&
                        <RestrictedArea key={menuItem.path} allowedTo={menuItem.accessLevel}>
                            <NavLink to={menuItem.path} activeClassName="active" className="admin" key={menuItem.path}>{ menuItem.icon &&
                                <div className={'icon'} style={{backgroundImage: `url(/icons/${menuItem.icon}.png`}}/>
                            }<div>{menuItem.menu}</div></NavLink>
                        </RestrictedArea>
                    )}
                </div>
            </div>
            <div>
                {adminPages.map(menuItem =>
                    <RestrictedArea key={menuItem.path} allowedTo={menuItem.accessLevel}>
                        <Route key={menuItem.path} path={menuItem.path} component={menuItem.component}/>
                    </RestrictedArea>
                )}
            </div>
        </div>
    </RestrictedArea>
);

export default AdminPortal;