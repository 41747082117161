import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {UserContext} from "../context/User";

class LogoutButton extends Component {
    state = {
        pressed: false
    };

    onLogout(e) {
        e.preventDefault();
        this.setState({pressed: true});
    };

    getUser() {
        return this.context;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.pressed && this.state.pressed) {
            this.getUser().logout();
        }
    }

    render() {
        if (this.state.pressed) {
            return <Redirect to='/' />
        }

        return (
            <a href="/#" className="right" onClick={this.onLogout.bind(this)}>Выйти</a>
        );
    }

}
LogoutButton.contextType = UserContext;

export default LogoutButton;

